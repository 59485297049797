<template>
    <div>
        <!-- Footer -->
        <div class="footer mt-3" :class="isMobile ? 'footer-sm' : 'footer-lg'" v-if="$route.path !== '/maung-fb' && $route.path !== '/body-fb'">
            <!-- <img src="/build/assets/img/brand/khit_thit.jpg" alt="" /> -->
            <div class="align-items-center justify-content-lg-between">
                <div class="col-lg-12 text-center">
                    <ul class="nav nav-footer justify-content-between">
                        <li
                            class="nav-item"
                            @click="$router.push('/home')"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <img class="mx-auto" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/b33f4e97-152f-4797-bbe9-9061a1f7c900/public" style="width: auto; height: 1.5rem;" alt="">
                                <div class="mx-auto pt-1 text-sm" style="color: white; font-weight: 700 !important;">
                                    {{$t('footer.home')}}
                                </div>
                            </div>
                        </li>
                        <!-- <li
                            class="nav-item"
                            @click="openModal()"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <img class="mx-auto" src="/build/assets/img/icons/Download.png" style="width: 18px; height: 18px" alt="">
                                <div class="mx-auto pt-1 text-sm" style="color: #a486ea; font-weight: 700 !important;">
                                    {{
                                        $store.state.language === "en"
                                            ? "Download"
                                            : "ဒေါင်းလုတ်"
                                    }}
                                </div>
                            </div>
                        </li> -->
                        <!-- :class="linkIsActive('/deposit') ? 'active' : ''" -->
                        <li
                            class="nav-item"
                            @click="$router.push('/promotion')"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <img class="mx-auto" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/9c1250c3-e5a9-437e-0d27-1a27170c1900/public" style="width: auto; height: 1.5rem;" alt="">
                                <div class="mx-auto pt-1 text-sm" style="color: white; font-weight: 700 !important;">
                                    {{$t('footer.promotion')}}
                                </div>
                            </div>
                        </li>
                        <li
                            class="nav-item"
                            @click="$router.push('/deposit')"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <img class="mx-auto" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/382b5e7b-4041-4658-0621-094c591d5500/public" style="width: auto; height: 1.5rem;" alt="">
                                <div class="mx-auto pt-1 text-sm" style="color: white; font-weight: 700 !important;">
                                    {{$t('footer.deposit')}}
                                </div>
                            </div>
                        </li>
                        <li
                            class="nav-item"
                        >
                            <a href="https://t.me/luckywin17888">
                                <div class="d-flex flex-column banking-icons">
                                    <img class="mx-auto" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/cd6c13ca-eaba-4b17-63b3-21622c896100/public" style="width: auto; height: 1.5rem;" alt="">
                                    <div class="mx-auto pt-1 text-sm" style="color: white; font-weight: 700 !important;">
                                        {{$t('setting.customer_service')}}
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            @click="$router.push('/setting')"
                        >
                            <div class="d-flex flex-column banking-icons">
                                <img class="mx-auto" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/7c971d89-2f7d-402a-6e21-1525e4715500/public" style="width: auto; height: 1.5rem;" alt="">
                                <div class="mx-auto pt-1 text-sm" style="color: white; font-weight: 700 !important;">
                                    {{$t('footer.profile')}}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast, Dialog } from 'vant';

export default {
  data() {
    return {
      is_live: false,
      isMobile: false,
    };
  },
  methods: {
    closeModal() {
      $('#download-modal').modal('hide');
    },
    openModal() {
      $('#download-modal').modal('show');
    },
    /* example link = '/settings' */
    linkIsActive(link) {
      console.log(this.$route.path === link);
      if (this.$route.path === link) return true;
      return false;
    },
    async fetchFootballLive() {
      try {
        const res = await axios.get('/football-lives');
        this.is_live = res.data.data[0].is_live;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchFootballLive();
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
  },
};
</script>
<style scoped>
.modal-content {
    border: 2px solid #714DC7;
}
td .fa-trash {
    color: red;
}
.modal-cross {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-top: 3px;
    margin-left: auto;
    color: white;
    cursor: pointer;
    margin-right: -9px;
    margin-top: -9px;
    background: #714DC7;
}
.banking-icons {
    width: auto;
    height: auto;
    padding: 5px;
    border-radius: 5px;
}
.bet-container {
    position: fixed;
    bottom: 0;
    margin-bottom: 50px;
    width: 50px;
    /* right: 0; */
    left: 5px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.25em;
    color: white;
    z-index: 100;
    visibility: visible;
    opacity: 1;
}
.nav-item {
    /* margin-left: 10px;
    margin-right: 10px; */
    /* border: 1px solid red; */
    font-size: 1rem;
    cursor: pointer;
    font-weight: 600;
    color: #02133E;
    min-width: 60px;
    /* height: 100px; */
}
/* .nav-item:hover,
.nav-item.active {
    border-radius: 5px;
    background: #02133E;
    color: red !important;
} */
/* @media (max-width: 767.98px) { */
    .footer {
        bottom: 0;
        height: 4rem !important;
        width: 100%;
        /* background: white;#F5C0C2 */
        background: #A80000;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        /* border-top: 2px solid black; */
    }
    .footer-sm {
        position: fixed;
    }
    .footer-lg {
        position: absolute;
    }
    .banking-icons {
        width: auto;
        height: auto;
        padding: 0.2rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-radius: 3px;
    }
    .footer {
        padding: 1px !important;
    }
    /* .banking-icons img {
        width: 25px;
        height: 25px;
        border-radius: 3px;
    } */
/* } */
</style>
