var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 p-0 m-0 mx-auto",class:_vm.$route.path === '/iframeURL' ? '' : 'col-lg-4',staticStyle:{"position":"relative","height":"100vh !important"}},[(
      !_vm.$route.path.includes('rule') &&
      _vm.$route.path !== '/home' &&
      _vm.$route.path !== '/' &&
      _vm.$route.path !== '/auth/login' &&
      _vm.$route.path !== '/auth/register' &&
      _vm.$route.path !== '/iframeURL'
    )?_c('NavBar'):_vm._e(),_c('router-view',{class:_vm.$route.path.includes('login') ? '' : 'middle-container',staticStyle:{"position":"relative","height":"100vh"}}),(!_vm.$route.path.includes('rule'))?_c('Footer'):_vm._e(),_c('van-overlay',{attrs:{"show":_vm.$store.state.isLoading}},[_c('div',{staticClass:"wrapper d-flex h-100vh",staticStyle:{"background":"rgba(0, 0, 0, 0.8)"}},[_c('div',{staticClass:"loader my-auto mx-auto"})])]),_c('van-overlay',{attrs:{"show":_vm.showPopup},on:{"click":function($event){_vm.showPopup = false}}},[_c('div',{staticClass:"wrapper col-12 d-flex h-100vh",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"mx-auto my-auto pt-4 px-4 pb-4",staticStyle:{"background":"rgba(0, 0, 0, 0.737)"}},[_c('b-carousel',{staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"carousel-1","interval":3000,"controls":"","fade":""}},_vm._l((_vm.websiteInfo.welcome_banners),function(banner,index){return _c('b-carousel-slide',{key:index,attrs:{"img-src":'https://yy24gld.sgp1.cdn.digitaloceanspaces.com/luckywin/banners/' +
              banner.url}})}),1),_c('div',{staticClass:"mt-3 d-flex flex-column"},[_c('div',{staticClass:"mx-auto mb-3"},[_c('div',{staticClass:"d-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isHideForToday),expression:"isHideForToday"}],staticClass:"mb-1 mr-2 p-0 m-0",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.isHideForToday)?_vm._i(_vm.isHideForToday,null)>-1:(_vm.isHideForToday)},on:{"change":function($event){var $$a=_vm.isHideForToday,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isHideForToday=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isHideForToday=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isHideForToday=$$c}}}}),_c('h3',{staticClass:"text-white text-center p-0 m-0"},[_vm._v("Hide For Today")])])]),_c('button',{staticClass:"btn btn-primary btn-block w-25 mx-auto text-uppercase",on:{"click":function($event){return _vm.closePopup()}}},[_vm._v(" Close ")])])],1)])]),_c('van-overlay',{attrs:{"show":_vm.isLuckyWheel}},[_c('div',{staticClass:"wrapper col-8",on:{"click":function($event){$event.stopPropagation();}}},[_c('LuckyWheel')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }