<template>
  <div class="container-fluid p-2 pt-3" style="scrollbar-width: none; -ms-overflow-style: none; background: #360000; height: 100vh; overflow-y: scroll; background-size: cover; padding-bottom: 15rem; background-attachment: fixed;">
        <div style="height: 200vh;">
          <!-- <div class="my-3 d-flex flex-wrap px-0 mx-0">
            <button class="detail-button w-auto text-nowrap mx-1 my-1">ALL</button>
            <button class="detail-button w-auto text-nowrap my-1">NEW MEMBER</button>
            <button class="detail-button w-auto text-nowrap mx-1 my-1">LIVE CASINO</button>
            <button class="detail-button w-auto text-nowrap my-1">LIVE CASINO</button>
            <button class="detail-button w-auto text-nowrap mx-1 my-1">LIVE CASINO</button>
            <button class="detail-button w-auto text-nowrap my-1">LIVE CASINO</button>
          </div> -->
          <div class="col-12 px-1 mb-5 pb-5">
            <div class="card bg-transparent" style="border: 2px solid #5B0C17; box-shadow: none;" v-for="(promotion, proIndex) in websiteInfo.promotions" :key="proIndex">
              <img :src="'https://yy24gld.sgp1.cdn.digitaloceanspaces.com/luckywin/banners/' + promotion[0].url" style="border-top-right-radius: 5px; border-top-left-radius: 5px;" alt="">
              <div class="px-3 py-2 d-flex justify-content-between bg-transparent">
                <h3 class="animate-charcter my-auto">
                  <picture class="mr-2">
                    <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.webp" type="image/webp">
                    <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.gif" alt="💸" width="25" height="25">
                  </picture>
                  {{ promotion[0].title }}
                  <picture class="ml-2">
                    <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.webp" type="image/webp">
                    <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.gif" alt="💸" width="25" height="25">
                  </picture>
                </h3>
                <button class="detail-button col-3" @click=" promotionIndex(proIndex)">{{$t('detail')}}</button>
              </div>

              <van-overlay :show="selectedPromotion === proIndex" @click="selectedPromotion = null">
                  <div class="wrapper col-12 d-flex h-100vh">
                    <div class="col-11 card mx-auto my-auto py-4" style="background: rgba(0, 0, 0, 0.8) !important;">
                      <h2 class="text-white pb-3"> {{ promotion[0].title }}</h2>
                      <span style="color: #B7AAAA"> {{ promotion[0].description }}</span>
                    </div>
                  </div>
              </van-overlay>

            </div>
          </div>
        </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      websiteInfo: [],
      isLoading: false,
      selectedPromotion: null,
      promotions: [
        {
          title: 'မန်ဘာသစ် 120 %',
          description: '10000 + 12000 = 22000 * 20ဆ = 440,000 ကစားမှတ် ပြည့်အောင်ကစားပေးရမည်။ စလော့ဂိမ်းတခုထဲသာလျှင်ကစားရမည်။ တစ်ခြားသောဂိမ်းများကစားထားခြင်းတွေ့ရှိပါက အနိုင်ငွေအားလုံးဝထုတ်ပေးမည်မဟုတ်ပါ။',
          image: 'https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/c635c796-62c9-43fd-144e-2f4ee6072400/public',
        },
        {
          title: 'Birthday Bonus 5%',
          image: 'https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/c635c796-62c9-43fd-144e-2f4ee6072400/public',
          description: 'ယခုဘောနပ်အားရယူရန် ကစားသူ၏ မွေးနေ့မှန်ကန်ကြောင်းကို ဖော်ပြပေးရပါမည်။ 3 ရက် အတွင်းသွင်းငွေ၏ 5 %ကိုရယူနိုင်ပါမည်။ ဘောနပ်၏ 3 ဆကစားပြီးပါကအနိုင်ငွေကိုထုတ်ယူနိုင်ပါသည် ( စလော့ဂိမ်းမှလွဲပြီးတစ်ခြားသောဂိမ်းများအားမကစားရ ကစားထားခြင်းတွေ့ရှိပါကအနိုင်ငွေထုတ်ပေးမည်မဟုတ်ပါ )။',
        },
        {
          title: 'ကာစီနို ဘောနပ် 5%',
          image: 'https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/c635c796-62c9-43fd-144e-2f4ee6072400/public',
          description: '10000 + 500 = 10500 * 3 = 31500 ကစားမှတ်ရှိမှငွေထုတ်နိုင်သည်။ ( ကာစီနိုသီးသန့်ကစားပေးရမည်။ တစ်ခြားသောဂိမ်းများကစားထားပါကအနိုင်ငွေထုတ်ပေးမည်မဟုတ်ပါ။ )',
        },
        {
          title: 'နေ့စဉ် 60%',
          image: 'https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/c635c796-62c9-43fd-144e-2f4ee6072400/public',
          description: 'တစ်ကြိမ်လျင် သွင်းငွေ၏ 20% အားတနေ့လျင် 3 ကြိမ်ရယူနိုင်ပါသည်။ 3 ကြိမ်အထဲမှ 1 ကြိမ်ဘောနပ်ယူကစားပြီးငွေထုတ်ထားသော ကစားသူများအား တနေ့တာအတွက်ကျန်ရှိသောဘောနပ်များအားဆက်လက်ခံစားခွင့်မရှိပါ။',
        },
        {
          title: 'အပါတ်စဉ် ရှုံးကြေး 7%',
          image: 'https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/c635c796-62c9-43fd-144e-2f4ee6072400/public',
          description: 'တပါတ်အတွင်းသွင်းငွေစုစုပေါင်း 100,000 အထက်ရှိသောကစား သူများသာခံစားခွင့်ရှိသည်။ အပါတ်စဉ်ရှုံးကြေးရယူပြီးသော ကစားသူများအနေဖြင့် ရှုံးကြေး၏ 10ဆ ကစားပြီးမှသာလျှင် အနိုင်ငွေကို ရရှိထားသော ရှုံးကြေးငွေ၏ 3 ဆ ကိုသာထုတ်ယူနိုင်သည်။ ကျန်ယူနစ်များကို ဆာဗာမှအော်တိုနုတ်ယူသွားမည်ဖြစ်သည်။',
        },
      ]
    };
  },
  methods: {
    promotionIndex(index) {
      this.selectedPromotion = index;
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        this.websiteInfo = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    // this.$store.commit('setLoading', true);
    await this.fetchWebsiteData();
    // this.$store.commit('setLoading', false);
  },
};
</script>
<style scoped>
.detail-button {
  appearance: none;
  background-color: transparent;
  border: 2px solid #A80000;
  text-transform: uppercase;
  border-radius: 14px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 0.9rem;
  font-weight: 450;
  line-height: normal;
  margin: 0;
  min-height: 15px;
  min-width: 0;
  outline: none;
  padding: 1px 5px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.detail-button:disabled {
  pointer-events: none;
}

.detail-button:hover {
  color: #fff;
  background-color: #A80000;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.detail-button:active {
  box-shadow: none;
  transform: translateY(0);
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #B50200 0%,
    #f3e706 29%,
    #B50200 67%,
    #f3e706 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      /* font-size: 190px; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
</style>